import React from "react";
import { createRoot } from 'react-dom/client';
import './styles/custom.scss';
import Home from "./components/Home/Home.jsx";
import Meetings from "./components/Meetings/Meetings.jsx";
import Players from "./components/Players/Players.jsx";
import BaseData from "./components/BaseData/BaseData.jsx";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { BrowserRouter, Routes, Route } from "react-router-dom";

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
const msalInstance = new PublicClientApplication(msalConfig);

/**
 * We recommend wrapping most or all of your components in the MsalProvider component. It's best to render the MsalProvider as close to the root as possible.
 */

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
    <React.StrictMode>
        <MsalProvider instance={msalInstance}>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/meetings" element={<Meetings />} />
                    <Route path="/players" element={<Players />} />
                    <Route path="/base" element={<BaseData />} />
                </Routes>
            </BrowserRouter>
        </MsalProvider>
    </React.StrictMode>
);
