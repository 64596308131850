import React, { useState, useContext } from "react";
import { Accordion, Button, Container, Row, Badge, Stack, AccordionContext } from 'react-bootstrap';
import { LoadSpinner } from "../Util/LoadSpinner";
import { MeetingEntryItem } from "./MeetingEntryItem";
import { CurrencyFormat } from "../Util/CurrencyFormat";
import { apiReadMeetingEntries, apiCreateOrUpdateMeetingEntry } from "../../pokerstatsAPI";
import { useMsal } from "@azure/msal-react";
import { MeetingEntryModal } from "./MeetingEntryModal";



export function MeetingItem(props) {

    const [meetingEntriesData, setMeetingEntriesData] = useState(null);
    const [showMeetingEntryModal, SetShowMeetingEntryModal] = useState(false);
    const [modalEntry, setModalEntry] = useState(null);
    const [modalError, setModalError] = useState(null);

    const { activeEventKey } = useContext(AccordionContext);

    const { instance } = useMsal();

    const meeting = props.meeting;
    const playerList = props.playerList;

    function toggleMeetingEntries() {
        if (meeting.meetingID != activeEventKey) {
            apiReadMeetingEntries(instance, meeting.meetingID).then(
                result => {
                    setMeetingEntriesData(result);
                }
            );
        } else {
            setMeetingEntriesData(null);
        }
    }

    function onMeetingEntryModalClose() {
        SetShowMeetingEntryModal(false);
    }

    function handleShowMeetingEntryModal(entry) {
        setModalError(null);
        if (entry) {
            setModalEntry({ ...entry, title: entry.playerName, isNew: false });
            SetShowMeetingEntryModal(true);
        } else {
            let newEntry = {};
            newEntry.playerList = [];
            let existingPlayerIds = {};
            meetingEntriesData.map(meetingEntry => (
                existingPlayerIds[meetingEntry.playerID] = true
            ));
            playerList.map(player => {
                if (!existingPlayerIds[player.id]) {
                    newEntry.playerList.push(player);
                }
            });
            newEntry.title = "New Entry";
            newEntry.meetingID = meeting.meetingID;
            newEntry.playerID = newEntry.playerList[0].id;
            newEntry.isNew = true;
            newEntry.bet = 20.00;
            newEntry.payout = 0.00;
            setModalEntry(newEntry);
            SetShowMeetingEntryModal(true);
        }
    }

    function handleSubmitMeetingEntryModal(entry) {

        let newEntry = { ...entry, type: "ENTRY" };

        if (entry.isNew) {
            newEntry.place = meeting.place;
            newEntry.placeID = meeting.placeID;
            newEntry.game = meeting.game;
            newEntry.gameID = meeting.gameID;
            newEntry.date = meeting.date;
            const player = playerList.find((player) => player.id == entry.playerID);
            newEntry.playerName = player.name;
        }

        apiCreateOrUpdateMeetingEntry(instance, entry.isNew, newEntry).then(entryResult => {
            setModalError(null);
            let updatedMeetingEntries = meetingEntriesData.slice();
            const searchIndex = updatedMeetingEntries.findIndex((meetingEntry) => meetingEntry.id == entryResult.id);
            if (searchIndex == -1) {
                updatedMeetingEntries.push(entryResult);
                updatedMeetingEntries.sort((a, b) => { return a.playerName.localeCompare(b.playerName); });
            } else {
                updatedMeetingEntries[searchIndex] = entryResult;
            }
            setMeetingEntriesData(updatedMeetingEntries);
            props.updateMeeting(meeting.meetingID);
            SetShowMeetingEntryModal(false);
        }
        ).catch(err => {
            console.log(err);
            setModalError(err)
        })
    }

    return (
        <Accordion.Item eventKey={meeting.meetingID}>
            <Accordion.Header onClick={toggleMeetingEntries}>
                <Container>
                    <Row>
                        <h5 className="m-0 mb-1 p-0">{meeting.date}</h5>
                    </Row>
                    <Row>
                        <small className="m-0 p-0">{meeting.place}</small>
                    </Row>
                    <Row>
                        <small className="m-0 p-0">{meeting.game}</small>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Badge pill bg="primary" className="mb-1">Attendees: {meeting.numberAttendees}</Badge>
                    </Row>
                    <Row>
                        <Badge pill bg="primary" className="mb-1">Total bet: <CurrencyFormat>{meeting.totalBet}</CurrencyFormat></Badge>
                    </Row>
                    <Row>
                        <Badge pill bg="primary" className="mb-1">Total Payout: <CurrencyFormat>{meeting.totalPayout}</CurrencyFormat></Badge>
                    </Row>
                </Container>
            </Accordion.Header>
            <Accordion.Body className="p-2">
                {playerList && playerList.length > meeting.numberAttendees ?
                    <Stack>
                        <Button variant="info" className="btn-lg" onClick={() => handleShowMeetingEntryModal()}>New Entry</Button>
                    </Stack>

                    : <Stack>
                        <Button variant="info" className="btn-lg" disabled>All players are already attending</Button>
                    </Stack>
                }
                {meetingEntriesData ?
                    meetingEntriesData.map(meetingEntry => (
                        <MeetingEntryItem key={meetingEntry.id} meetingEntryData={meetingEntry} showModal={handleShowMeetingEntryModal} />
                    ))
                    :
                    <LoadSpinner />
                }
            </Accordion.Body>
            {meetingEntriesData && <MeetingEntryModal error={modalError} show={showMeetingEntryModal} entry={modalEntry} handleClose={onMeetingEntryModalClose} handleSubmit={handleSubmitMeetingEntryModal} />}
        </Accordion.Item>
    );

}
