import React, { useState, useEffect } from "react";
import { PageLayout } from "../PageLayout";
import { apiReadPlayers } from "../../pokerstatsAPI";
import { useMsal } from "@azure/msal-react";
import { Accordion, Button } from 'react-bootstrap';
import { PlayerItem } from "./PlayerItem";
import { LoadSpinner } from "../Util/LoadSpinner";

function PlayerList() {
    const [playerListData, setPlayerListData] = useState([]);

    const { instance } = useMsal();

    const pageSize = 20;

    useEffect(() => {
        apiReadPlayers(instance).then(result => {
            setPlayerListData(result);
        });            
    }, []);

    if (playerListData && playerListData.length > 0) {
        return (
            <>
                <Accordion>
                    {playerListData.map(player => (
                        <PlayerItem key={player.id} player={player} />
                    ))}
                </Accordion>
            </>
        );
    } else {
        return (
            <LoadSpinner />
        );
    }
};

export default function Players() {
    return (
        <PageLayout>
            <PlayerList />
        </PageLayout>
    );
}
