import { loginRequest } from "./authConfig";

const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

export async function apiReadPlayers(instance) {
    return await getAPI(instance, "/Players/GetAllPlayers");
}

export async function apiReadPlayer(instance, playerID) {
    return await getAPI(instance, "/Players/GetPlayer/" + playerID);
}

export async function apiReadPlayerEntries(instance, playerID) {
    return await getAPI(instance, "/Players/GetPlayerEntries/" + playerID);
}

export async function apiReadPaginatedPlayerEntries(instance, playerID, pageSize, continuationToken) {
    const url = continuationToken ? "/Players/GetPaginatedPlayerEntries/" + playerID + "/" + pageSize + "/" + continuationToken : "/Players/GetPaginatedPlayerEntries/" + playerID + "/" + pageSize;
    return await getAPI(instance, url);
}

export async function apiCreatePlayer(instance, entry) {
    return await postAPI(instance, "/Players/CreatePlayer", entry);
}

export async function apiUpdatePlayer(instance, entry) {
    return await postAPI(instance, "/Players/UpdatePlayer", entry);
}

export async function apiCreateListEntry(instance, entry) {
    return await postAPI(instance, "/Lists/CreateListEntry", entry);
}

export async function apiUpdateListEntry(instance, entry) {
    return await postAPI(instance, "/Lists/UpdateListEntry", entry);
}


export async function apiReadMeetings(instance) {
    return await getAPI(instance, "/Meetings/GetAllMeetings");
}

export async function apiReadPaginatedMeetings(instance, pageSize, continuationToken) {
    const url = continuationToken ? "/Meetings/GetPaginatedMeetings/" + pageSize + "/" + continuationToken : "/Meetings/GetPaginatedMeetings/" + pageSize;
    return await getAPI(instance, url);
}


export async function apiReadMeeting(instance, meetingID) {
    return await getAPI(instance, "/Meetings/GetMeeting/" + meetingID);
}

export async function apiReadMeetingEntries(instance, meetingID) {
    return await getAPI(instance, "/Meetings/GetMeetingEntries/" + meetingID);
}

export async function apiReadList(instance, type) {
    return await getAPI(instance, "/Lists/Get/" + type);
}

export async function apiCreateOrUpdateMeetingEntry(instance, isNew, entry) {
    const url = isNew ? "/Meetings/CreateMeetingEntry" : "/Meetings/UpdateMeetingEntry";
    return await postAPI(instance, url, entry);
}

export async function apiCreateMeeting(instance, entry) {
    return await postAPI(instance, "/Meetings/CreateMeeting", entry);
}

async function postAPI(instance, url, body) {

    return renewAccessToken(instance).then(token => {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("Authorization", bearer);
        headers.append("Content-Type", 'application/json');

        const options = {
            method: "POST",
            headers: headers,
            body: JSON.stringify(body)
        };

        return fetch(apiBaseURL + url, options)
            .then(async response => {
                let json = await response.json();
                if (!response.ok) {
                    return Promise.reject(json)
                }
                return json;
            })
            .catch(error => {
                console.log(error);
                throw (error);
            });
    }

    )
}


async function getAPI(instance, url) {

    return renewAccessToken(instance).then(token => {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("Authorization", bearer);

        const options = {
            method: "GET",
            headers: headers
        };

        return fetch(apiBaseURL + url, options)
            .then(async response => {
                let json = await response.json();
                if (!response.ok) {
                    return Promise.reject(json)
                }
                return json;
            })
            .catch(error => {
                console.log(error);
                throw (error);
            });
    }

    )
}


async function renewAccessToken(instance) {
    const account = instance.getAllAccounts()[0];
    return instance.acquireTokenSilent({
        ...loginRequest,
        account: account
    }).then((response) => {
        return response.accessToken
    }).catch(error => {
        console.log(error);
        throw (error);
    });

}


