import React, { useState, useEffect } from "react";
import { apiReadList, apiCreateListEntry, apiUpdateListEntry } from "../../pokerstatsAPI";
import { useMsal } from "@azure/msal-react";
import { Container, Row, Col, Stack, Button } from 'react-bootstrap';
import { LoadSpinner } from "../Util/LoadSpinner";
import { ListModal } from "./ListModal";

export function List(props) {
    const [listData, setListData] = useState([]);
    const [showModal, SetShowModal] = useState(false);
    const [modalEntry, setModalEntry] = useState(null);
    const [modalError, setModalError] = useState(null);

    const { instance } = useMsal();

    const listType = props.listType;
    const listTypeName = props.listTypeName;

    useEffect(() => {
        apiReadList(instance, listType).then(result => {
            setListData(result);
        });
    }, []);

    function onModalClose() {
        SetShowModal(false);
    }

    function handleShowModal(entry) {
        setModalError(null);
        if (entry) {
            setModalEntry({ ...entry, title: entry.name, isNew: false });
            SetShowModal(true);
        } else {
            let newEntry = {};
            newEntry.title = "New Entry";
            newEntry.name = "";
            newEntry.isNew = true;
            setModalEntry(newEntry);
            SetShowModal(true);
        }
    }

    function handleSubmitModal(entry) {

        let newEntry = { ...entry, listType: listType };

        if (entry.isNew) {
            apiCreateListEntry(instance, newEntry).then(entryResult => {
                setModalError(null);
                let updatedListData = listData.slice();
                updatedListData.push(entryResult);
                updatedListData.sort((a, b) => { return a.name.localeCompare(b.name); });
                setListData(updatedListData);
                SetShowModal(false);
            }
            ).catch(err => {
                console.log(err);
                setModalError(err)
            })
        } else {
            apiUpdateListEntry(instance, newEntry).then(entryResult => {
                setModalError(null);
                let updatedListData = listData.slice();
                const searchIndex = updatedListData.findIndex((listEntry) => listEntry.id == entryResult.id);
                updatedListData[searchIndex] = entryResult;
                setListData(updatedListData);
                SetShowModal(false);
            }
            ).catch(err => {
                console.log(err);
                setModalError(err)
            })

        }


    }


    if (listData && listData.length > 0) {
        return (
            <>
                <Stack>
                    <Button variant="info" className="btn-lg" onClick={() => handleShowModal()}>New {listTypeName}</Button>
                </Stack>
                <Container>
                {listData.map(entry => (
                        <Row key={entry.id} className="align-items-center p-2">
                            <Col className="col-8">
                                <h5 className="m-0 p-0">{entry.name}</h5>
                            </Col>
                            <Button as={Col} className="col-4" variant="primary" onClick={() => handleShowModal(entry)}>Edit {listTypeName}</Button>
                        </Row>
                    ))}
                </Container>
                <ListModal error={modalError} listTypeName={listTypeName} show={showModal} entry={modalEntry} handleClose={onModalClose} handleSubmit={handleSubmitModal} />
            </>
        );
    } else {
        return (
            <LoadSpinner />
        );
    }
};
