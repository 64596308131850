import React, { useState, useEffect, useRef } from "react";
import { PageLayout } from "../PageLayout";
import { apiReadPaginatedMeetings, apiReadMeetingEntries, apiReadList, apiCreateOrUpdateMeetingEntry, apiReadMeeting, apiCreateMeeting } from "../../pokerstatsAPI";
import { useMsal } from "@azure/msal-react";
import { Accordion, Button, Stack } from 'react-bootstrap';
import { MeetingItem } from "./MeetingItem";
import { MeetingModal } from "./MeetingModal";
import { LoadSpinner } from "../Util/LoadSpinner";
import useInfiniteScroll from 'react-infinite-scroll-hook';


export function MeetingsList(props) {
    const [meetingListData, setMeetingListData] = useState([]);
    const [showMeetingModal, SetShowMeetingModal] = useState(false);
    const [playerList, setPlayerList] = useState(null);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [continuationToken, setContinuationToken] = useState(null);
    const [modalError, setModalError] = useState(null);

    const { instance } = useMsal();

    const pageSize = props.pageSize ? props.pageSize : 20;
    const maxNumber = props.maxNumber ? props.maxNumber : -1;

    useEffect(() => {
        apiReadList(instance, "PLAYERS").then(result => {
            setPlayerList(result);
        });
    }, []);


    function getMoreMeetings() {
        if (maxNumber == -1 || (meetingListData && maxNumber > meetingListData.length)) {
            setLoading(true);
            setHasNextPage(false);
            apiReadPaginatedMeetings(instance, pageSize, continuationToken).then(result => {
                setMeetingListData(prevData => ([...prevData, ...result.meetings]));
                if (result.continuationToken) {
                    setHasNextPage(true);
                    setContinuationToken(result.continuationToken);
                }
                setLoading(false);
            });
        } else {
            setLoading(false);
            setHasNextPage(false);
            setContinuationToken(null);
        }
    }

    function onMeetingModalClose() {
        SetShowMeetingModal(false);
    }
    function handleShowMeetingModal() {
        setModalError(null);
        SetShowMeetingModal(true);

    }

    function handleSubmitMeetingModal(entry) {
        let newEntry = {};
        newEntry.place = entry.place;
        newEntry.placeID = entry.placeID;
        newEntry.game = entry.game;
        newEntry.gameID = entry.gameID;
        newEntry.date = entry.date;

        apiCreateMeeting(instance, newEntry).then(result => {
            setModalError(null);
            let updatedMeetingListData = meetingListData.slice();
            updatedMeetingListData.push(result);
            updatedMeetingListData.sort((a, b) => { return new Date(b.date) - new Date(a.date); });
            setMeetingListData(updatedMeetingListData);
            SetShowMeetingModal(false);
        }
        ).catch(err => {
            console.log(err);
            setModalError(err)
        })
    }

    function updateMeeting(meetingID) {
        apiReadMeeting(instance, meetingID).then(meetingResult => {
            let updatedMeetingList = meetingListData.slice();
            const searchIndex = updatedMeetingList.findIndex((meeting) => meeting.id == meetingResult.id);
            updatedMeetingList[searchIndex] = meetingResult;
            setMeetingListData(updatedMeetingList);
        }
        )
    }

    const [sentryRef] = useInfiniteScroll({
        loading,
        hasNextPage: hasNextPage,
        delayInMs: 500,
        onLoadMore: () => {
            getMoreMeetings();
        }
    })

    return (
        <>
            <Stack className="my-2">
                <Button variant="info" className="btn-lg" onClick={handleShowMeetingModal}>New Meeting</Button>
            </Stack>
            {(meetingListData && meetingListData.length > 0) &&
                <Accordion>
                    {meetingListData.map(meeting => (
                        <MeetingItem key={meeting.id} meeting={meeting} playerList={playerList} updateMeeting={updateMeeting} />
                    ))}
                </Accordion>
            }
            {(loading || hasNextPage) && (maxNumber == -1 || (meetingListData && maxNumber > meetingListData.length)) && <LoadSpinner ref={sentryRef} />}
            <MeetingModal error={modalError} show={showMeetingModal} handleClose={onMeetingModalClose} handleSubmit={handleSubmitMeetingModal} />
        </>
    );
};

export default function Meetings() {
    return (
        <PageLayout>
            <MeetingsList />
        </PageLayout>
    );
}
