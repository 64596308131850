import React from "react";
import {NumericFormat} from "react-number-format";

export function CurrencyFormat(props) {

    return (
        <NumericFormat 
        value={props.children} 
        thousandSeparator="."
        decimalSeparator=","
        decimalScale="2"
        fixedDecimalScale="true"
        allowNegative="true"
        displayType="text"
        suffix="€"
         />
    );
};

