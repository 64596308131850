import React from "react";
import { PageLayout } from "../PageLayout";
import { PlayerList } from "./Players";
import { List } from "./List";
import {Tabs, Tab} from 'react-bootstrap'


export default function BaseData() {
    return (
        <PageLayout>
            <Tabs defaultActiveKey="players"className="mb-3">
                <Tab eventKey="players" title="Players">
                    <PlayerList />
                </Tab>
                <Tab eventKey="places" title="Places">
                    <List listType="PLACES" listTypeName="Place"/>
                </Tab>
                <Tab eventKey="games" title="Games">
                <List listType="GAMES" listTypeName="Game"/>
                </Tab>
            </Tabs>
        </PageLayout>
    );
}
