import React, { useState, useEffect } from "react";
import { Alert,Modal, Button, Spinner, Form } from 'react-bootstrap';
import { apiReadList } from "../../pokerstatsAPI";
import { useMsal } from "@azure/msal-react";

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const ListModal = (props) => {
    const [modalEntry, setModalEntry] = useState(null);

    useEffect(() => {
        setModalEntry(props.entry);
    }, [props.entry]);

    function updateModalEntry(event, property) {
        const target = event.target

        event.preventDefault()
        setModalEntry((prevState) => ({
            ...prevState,
            [property]: event.target.value,
        }))
    }

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header>
                {modalEntry ?
                    <div>{modalEntry.title}</div>
                    :
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }
            </Modal.Header>
            <Modal.Body>
                {modalEntry ?
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>{props.listTypeName} Name</Form.Label>
                            <Form.Control type="text" value={modalEntry.name} onChange={(e) => updateModalEntry(e, 'name')} />
                        </Form.Group>
                    </Form>
                    :
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }
                {props.error &&
                    <Alert variant="danger">
                        <p>{props.error.errorMessage}</p>
                    </Alert>
                }

            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => { props.handleSubmit(modalEntry) }}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};