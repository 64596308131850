import React from "react";
import { Spinner, Stack } from 'react-bootstrap';

export const LoadSpinner = React.forwardRef((props, ref) => (
  <Stack>
    <Spinner animation="border" role="status" ref={ref} className="mx-auto my-2">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </Stack>
));
