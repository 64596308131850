import React, { useState, useEffect } from "react";
import { Modal, Button, Spinner, Form, Alert } from 'react-bootstrap';
import { apiReadList } from "../../pokerstatsAPI";
import { useMsal } from "@azure/msal-react";

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const PlayerModal = (props) => {
    const [modalEntry, setModalEntry] = useState(null);
    const [mailValid, setMailValid] = useState(true);

    useEffect(() => {
        setModalEntry(props.entry);
    }, [props.entry]);

    function updateModalEntry(event, property) {
        const target = event.target

        event.preventDefault()
        setModalEntry((prevState) => ({
            ...prevState,
            [property]: event.target.value,
        }))
    }

    const regexExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/gi;

    function updateMail(event) {
        event.preventDefault()
        setModalEntry((prevState) => ({
            ...prevState,
            email: event.target.value
        }));
        if (event.target.value=="" || regexExp.test(event.target.value)) {
            setMailValid(true);
        } else {
            setMailValid(false);
        }
    }

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header>
                {modalEntry ?
                    <div>{modalEntry.title}</div>
                    :
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }
            </Modal.Header>
            <Modal.Body>
                {modalEntry ?
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicName">
                            <Form.Label>Player Name</Form.Label>
                            <Form.Control type="text" value={modalEntry.playerName} onChange={(e) => updateModalEntry(e, 'playerName')} />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label>Player Email</Form.Label>
                            <Form.Control type="email" value={modalEntry.email} onChange={updateMail} />
                        </Form.Group>
                    </Form>
                    :
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }
                {!mailValid &&
                    <Alert variant="warning">
                        <p>You haven't entered a valid mail-address!</p>
                    </Alert>
                }
                {props.error &&
                    <Alert variant="danger">
                        <p>{props.error.errorMessage}</p>
                    </Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => { props.handleSubmit(modalEntry) }}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};