import React, { useState, useEffect } from "react";
import { apiReadPlayers, apiCreatePlayer, apiUpdatePlayer } from "../../pokerstatsAPI";
import { useMsal } from "@azure/msal-react";
import { Container, Row, Col, Button, Stack } from 'react-bootstrap';
import { LoadSpinner } from "../Util/LoadSpinner";
import { PlayerModal } from "./PlayerModal";

export function PlayerList() {
    const [playerListData, setPlayerListData] = useState([]);
    const [showPlayerModal, SetShowPlayerModal] = useState(false);
    const [modalEntry, setModalEntry] = useState(null);
    const [modalError, setModalError] = useState(null);

    const { instance } = useMsal();

    useEffect(() => {
        apiReadPlayers(instance).then(result => {
            setPlayerListData(result);
        });
    }, []);

    function onPlayerModalClose() {
        SetShowPlayerModal(false);
    }

    function handleShowPlayerModal(entry) {
        setModalError(null);
        if (entry) {
            setModalEntry({ ...entry, title: entry.playerName, isNew: false });
            SetShowPlayerModal(true);
        } else {
            let newEntry = {};
            newEntry.title = "New Entry";
            newEntry.playerName = "";
            newEntry.email = "";
            newEntry.isNew = true;
            setModalEntry(newEntry);
            SetShowPlayerModal(true);
        }
    }

    function handleSubmitPlayerModal(entry) {

        let newEntry = { ...entry, type: "PLAYER" };

        if (entry.isNew) {
            apiCreatePlayer(instance, newEntry).then(entryResult => {
                setModalError(null);
                let updatedPlayerListData = playerListData.slice();
                updatedPlayerListData.push(entryResult);
                updatedPlayerListData.sort((a, b) => { return a.playerName.localeCompare(b.playerName); });
                setPlayerListData(updatedPlayerListData);
                SetShowPlayerModal(false);
            }
            ).catch(err => {
                console.log(err);
                setModalError(err)
            })
        } else {
            apiUpdatePlayer(instance, newEntry).then(entryResult => {
                setModalError(null);
                let updatedPlayerListData = playerListData.slice();
                const searchIndex = updatedPlayerListData.findIndex((player) => player.id == entryResult.id);
                updatedPlayerListData[searchIndex] = entryResult;
                updatedPlayerListData.sort((a, b) => { return a.playerName.localeCompare(b.playerName); });
                setPlayerListData(updatedPlayerListData);
                SetShowPlayerModal(false);
            }
            ).catch(err => {
                console.log(err);
                setModalError(err)
            })
        }


    }


    if (playerListData && playerListData.length > 0) {
        return (
            <>
                <Stack>
                    <Button variant="info" className="btn-lg" onClick={() => handleShowPlayerModal()}>New Player</Button>
                </Stack>
                <Container>
                    {playerListData.map(player => (
                        <Row key={player.id} className="align-items-center p-2">
                            <Col className="col-8">
                                <h5 className="m-0 p-0">{player.playerName}</h5>
                            </Col>
                            <Button as={Col} className="col-4" variant="primary" onClick={() => handleShowPlayerModal(player)}>Edit Player</Button>
                        </Row>
                    ))}
                </Container>
                <PlayerModal error={modalError} show={showPlayerModal} entry={modalEntry} handleClose={onPlayerModalClose} handleSubmit={handleSubmitPlayerModal} />
            </>
        );
    } else {
        return (
            <LoadSpinner />
        );
    }
};
