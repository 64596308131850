import React, { useState, useEffect } from "react";
import { Alert, Modal, Button, Spinner, Form } from 'react-bootstrap';
import { apiReadList } from "../../pokerstatsAPI";
import { useMsal } from "@azure/msal-react";

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const MeetingModal = (props) => {
    const [modalEntry, setModalEntry] = useState(null);
    const [placesMap, setPlacesMap] = useState(null);
    const [gamesMap, setGamesMap] = useState(null);

    const { instance } = useMsal();

    useEffect(() => {
        let entry = {};
        const today = new Date();

        entry.date = today.getFullYear() + "-" + (today.getMonth() + 1).toString().padStart(2, '0') + "-" + today.getDate().toString().padStart(2, '0');

        apiReadList(instance, "PLACES").then(result => {
            entry.placeList = result;
            let placeMap = {};
            result.map(place => {
                placeMap[place.id] = place.name;
            });
            setPlacesMap(placeMap);
            entry.place = result[0].name;
            entry.placeID = result[0].id;
            apiReadList(instance, "GAMES").then(result => {
                entry.gameList = result;
                let gamesMap = {};
                result.map(game => {
                    gamesMap[game.id] = game.name;
                });
                setGamesMap(gamesMap);
                entry.game = result[0].name;
                entry.gameID = result[0].id;
                setModalEntry(entry);
            });
        });
    }, [props.show]);

    function updateModalEntry(event, property) {
        const target = event.target

        event.preventDefault()
        setModalEntry((prevState) => ({
            ...prevState,
            [property]: event.target.value,
        }))
    }

    function updatePlace(event) {
        event.preventDefault()
        setModalEntry((prevState) => ({
            ...prevState,
            place: placesMap[event.target.value],
            placeID: event.target.value
        }))
    }

    function updateGame(event) {
        event.preventDefault()
        setModalEntry((prevState) => ({
            ...prevState,
            game: gamesMap[event.target.value],
            gameID: event.target.value
        }))
    }

    return (
        <Modal show={props.show} onHide={props.handleClose}>
            <Modal.Header>
                {modalEntry ?
                    <div>New Meeting</div>
                    :
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }
            </Modal.Header>
            <Modal.Body>
                {modalEntry ?
                    <Form>
                        <Form.Group className="mb-3" controlId="formBasicPlace">
                            <Form.Label>Place</Form.Label>
                            <Form.Select onChange={(e) => updatePlace(e)}>
                                {modalEntry.placeList.map(place => (
                                    <option value={place.id} key={place.id}>{place.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicGame">
                            <Form.Label>Game</Form.Label>
                            <Form.Select onChange={(e) => updateGame(e)}>
                                {modalEntry.gameList.map(game => (
                                    <option value={game.id} key={game.id}>{game.name}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicDate">
                            <Form.Label>Select Date</Form.Label>
                            <Form.Control type="date" value={modalEntry.date} placeholder="Date of Meeting" onChange={(e) => updateModalEntry(e, 'date')} />
                        </Form.Group>
                    </Form>
                    :
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </Spinner>
                }
                {props.error &&
                    <Alert variant="danger">
                        <p>{props.error.errorMessage}</p>
                    </Alert>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={props.handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={() => { props.handleSubmit(modalEntry) }}>
                    Save
                </Button>
            </Modal.Footer>
        </Modal>
    );
};