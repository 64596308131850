import React from "react";
import { Card, Button, Container, Row, Col } from 'react-bootstrap';
import { CurrencyFormat } from "../Util/CurrencyFormat";

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const MeetingEntryItem = (props) => {
    let earnStyle;
    if (props.meetingEntryData.earn >= 0) {
        earnStyle = "bg-success rounded-end"
    } else {
        earnStyle = "bg-danger rounded-end"
    }

    return (
        <Card className="shadow-sm my-2">
            <Card.Header><h5>{props.meetingEntryData.playerName}</h5></Card.Header>
            <Card.Body className="p-1 m-1">
                <Container className="m-0" fluid>
                    <Row>
                        <Col className="bg-danger rounded-start">
                            <p className="mb-0">Bet:</p>
                            <h3 className="mt-0 text-center"><CurrencyFormat>{props.meetingEntryData.bet}</CurrencyFormat></h3>
                        </Col>
                        <Col className="bg-success">
                            <p className="mb-0">Payout:</p>
                            <h3 className="mt-0 text-center"><CurrencyFormat>{props.meetingEntryData.payout}</CurrencyFormat></h3>
                        </Col>
                        <Col className={earnStyle}>
                            <p className="mb-0">Earn:</p>
                            <h3 className="mt-0 text-center"><CurrencyFormat>{props.meetingEntryData.earn}</CurrencyFormat></h3>
                        </Col>
                    </Row>
                    <Row className="pt-1">
                        <Button as={Col} variant="primary" onClick={() => { props.showModal(props.meetingEntryData) }}>Edit Entry</Button>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    );
};