import React, { useState, useEffect } from "react";
import { PageLayout } from "../PageLayout";
import { MeetingsList } from "../Meetings/Meetings";
import { Accordion, Button } from 'react-bootstrap';
import { PlayerItem } from "../Players/PlayerItem";
import { apiReadPlayer } from "../../pokerstatsAPI";
import { useMsal } from "@azure/msal-react";


const MainContent = () => {
    const [player, setPlayer] = useState(null);

    const { instance, accounts } = useMsal();


    useEffect(() => {
        apiReadPlayer(instance, accounts[0].idTokenClaims.extension_playerID).then(result => {
            setPlayer(result);
        });            
    }, []);


    return (
        <>
            <MeetingsList maxNumber="1" pageSize="1" />
            <Accordion>
                {player && <PlayerItem key={player.id} player={player} />}
            </Accordion>
        </>
    );
};

export default function Home() {
    return (
        <PageLayout>
            <MainContent />
        </PageLayout>
    );
}
