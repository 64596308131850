import React, { useState, useContext } from "react";
import { Accordion, AccordionContext, Container, Row, Badge } from 'react-bootstrap';
import { LoadSpinner } from "../Util/LoadSpinner";
import { PlayerEntryItem } from "./PlayerEntryItem";
import { CurrencyFormat } from "../Util/CurrencyFormat";
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { useMsal } from "@azure/msal-react";
import { apiReadPaginatedPlayerEntries } from "../../pokerstatsAPI";

export function PlayerItem(props) {
    const [playerEntriesData, setPlayerEntriesData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [continuationToken, setContinuationToken] = useState(null);
    const { instance } = useMsal();
    const { activeEventKey } = useContext(AccordionContext);


    const player = props.player;
    const pageSize = 20;

    function getMorePlayerEntries() {
        setLoading(true);
        apiReadPaginatedPlayerEntries(instance, player.playerID, pageSize, continuationToken).then(result => {
            setPlayerEntriesData(prevData => ([...prevData, ...result.playerEntries]));
            if (result.continuationToken) {
                setHasNextPage(true);
                setContinuationToken(result.continuationToken);
            } else {
                setHasNextPage(false);
                setContinuationToken(null);                
            }
            setLoading(false);
        });
    }

    function togglePlayerEntries() {
        if (player.playerID != activeEventKey) {
            setPlayerEntriesData([]);
            setContinuationToken(null);
            setHasNextPage(false);
            getMorePlayerEntries();
        } else {
            setPlayerEntriesData([]);
            setContinuationToken(null);
            setHasNextPage(false);   
        }
    }

    const [sentryRef] = useInfiniteScroll({
        loading, 
        hasNextPage: hasNextPage ,
        delayInMs:500,
        onLoadMore: () => {
            getMorePlayerEntries();
        }
    })

    let earnStyle;
    if (player.totalEarn >= 0) {
        earnStyle = "success"
    } else {
        earnStyle = "danger"
    }


    return (
        <Accordion.Item eventKey={player.playerID}>
            <Accordion.Header onClick={togglePlayerEntries}>
            <Container>
                    <Row>
                        <h5 className="m-0 mb-1 p-0">{player.playerName}</h5>
                    </Row>
                </Container>
                <Container>
                    <Row>
                        <Badge pill bg="danger" className="mb-1">Total bet: <CurrencyFormat>{player.totalBet}</CurrencyFormat></Badge>
                    </Row>
                    <Row>
                        <Badge pill bg="success" className="mb-1">Total payout: <CurrencyFormat>{player.totalPayout}</CurrencyFormat></Badge>
                    </Row>
                    <Row>
                        <Badge pill bg={earnStyle} className="mb-1">Total earn: <CurrencyFormat>{player.totalEarn}</CurrencyFormat></Badge>
                    </Row>
                </Container>
            </Accordion.Header>
            <Accordion.Body>
                {playerEntriesData ?
                    playerEntriesData.map(playerEntry => (
                        <PlayerEntryItem key={playerEntry.id} playerEntryData={playerEntry} />
                    )) : <div />
                }
                {(loading || hasNextPage) && <LoadSpinner ref={sentryRef} />}
            </Accordion.Body>
        </Accordion.Item>
    );

}

